import React, { useState, useEffect } from "react";
import { Typography, Box, Button } from "@mui/material";
import decode from "../../../general/util/jwtDecode";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import customFetch from "../../../general/auth/customFetch";
import { PARTNER_ADMIN_ROLE_NAME } from "../../../general/constants/constants";
import CreateUserDialog from "./components/CreateUserDialog";
import AddIcon from '@mui/icons-material/Add';

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function PartnerTenantUsers() {
  const currentUser = decode();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [tenants, setTenants] = useState([]);
  const [createDialogOpen, setCreateDialogOpen] = useState(false);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const fetchData = async () => {
    try {
      const tenantsResponse = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenants`
      );
      const tenantsData = await tenantsResponse.json();
      setTenants(tenantsData);

      const usersResponse = await customFetch(
        `/v1/partners/${currentUser.partner_id}/tenant-users`
      );
      const usersData = await usersResponse.json();

      const combinedData = usersData.map((user) => ({
        ...user,
        full_name: `${user.first_name} ${user.last_name}`,
        tenant_name: tenantsData.find((tenant) => tenant.id === user.tenant_id)?.name || 'Unknown',
      }));

      setUsers(combinedData);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
    }
  };

  useEffect(() => {
    if (!currentUser) {
      window.location = "/login";
    } else if (currentUser && currentUser.partner_role !== PARTNER_ADMIN_ROLE_NAME) {
      window.location = "/";
    } else {
      fetchData();
    }
  }, []);

  const columns = [
    {
      field: "full_name",
      headerName: "User Name",
      flex: 0.75,
    },
    { field: "email", headerName: "Email", flex: 1.4 },
    { field: "tenant_name", headerName: "Tenant", flex: 1 },
    { field: "role_name", headerName: "Role", flex: .8 },
    {
      field: "crm_coupon",
      headerName: "CRM Coupon",
      flex: 0.55,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
    {
      field: "onboarded",
      headerName: "Onboarded",
      flex: 0.55,
      type: "boolean",
      align: "left",
      headerAlign: "left",
    },
  ];

  const handleCreateSuccess = (newUser) => {
    setUsers((prevUsers) => {
      const updatedUser = {
        ...newUser,
        full_name: `${newUser.first_name} ${newUser.last_name}`,
        tenant_name: tenants.find((tenant) => tenant.id === newUser.tenant_id)?.name || 'Unknown',
      };
      return [...prevUsers, updatedUser];
    });
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <Typography
        variant="h2"
        component="h2"
        gutterBottom
        sx={{
          color: "primary.main",
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        Partner Tenant Users
      </Typography>
      <Typography
        variant="body1"
        sx={{
          marginBottom: 2,
          textAlign: "left",
        }}
      >
        Partner tenant users are users that belong to specific tenants within your partner organization. 
        Here you can manage user access and roles across all your tenants.
      </Typography>

      <Button
        color="primary"
        variant="contained"
        startIcon={<AddIcon />}
        onClick={() => setCreateDialogOpen(true)}
        sx={{ mb: 3 }}
      >
        Add Tenant User
      </Button>

      <CreateUserDialog
        open={createDialogOpen}
        onClose={() => setCreateDialogOpen(false)}
        onSuccess={handleCreateSuccess}
        partnerId={currentUser.partner_id}
        tenants={tenants}
      />

      <DataGridPro
        rows={users}
        columns={columns}
        loading={loading}
        components={{
          Toolbar: CustomToolbar,
          NoRowsOverlay: CustomNoRowsOverlay,
        }}
        pageSize={10}
        initialState={{
          pagination: {
            paginationModel: {
              pageSize: 10,
            },
          },
        }}
        headerFilters
        pageSizeOptions={[10, 25, 50]}
        pagination
        sx={{
          height: "calc(100vh - 300px)",
          "& .MuiDataGrid-cell:focus-within": {
            outline: "none !important",
          },
        }}
      />
    </Box>
  );
}
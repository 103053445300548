import React, { useState, useEffect } from "react";
import { Typography, Box, Button, IconButton } from "@mui/material";
import {
  Add as AddIcon,
  Edit as EditIcon,
  Delete as DeleteIcon,
} from "@mui/icons-material";
import {
  DataGridPro,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
} from "@mui/x-data-grid-pro";
import CustomNoRowsOverlay from "../../../general/layout/CustomNoRowsOverlay";
import { LicenseInfo } from "@mui/x-data-grid-pro";
import CreateUser from "./components/CreateUser";
import EditUser from "./components/EditUser";
import DeleteUser from "./components/DeleteUser";
import customFetch from "../../../general/auth/customFetch";
import decode from "../../../general/util/jwtDecode";

LicenseInfo.setLicenseKey(
  "be7a234dde76fa29710026b4e9ee32f4Tz04ODU3NCxFPTE3NDQ5NDEzNzYwMDAsUz1wcm8sTE09cGVycGV0dWFsLEtWPTI="
);

export default function PartnerUsers() {
  const currentUser = decode();
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [openCreate, setOpenCreate] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton />
        <GridToolbarFilterButton />
      </GridToolbarContainer>
    );
  }

  const fetchUsers = async () => {
    try {
      const response = await customFetch(
        `/v1/partners/${currentUser.partner_id}/users/`
      );
      const jsonData = await response.json();

      const combinedData = jsonData.map((row) => ({
        ...row,
        full_name: `${row.first_name} ${row.last_name}`,
      }));

      setUsers(combinedData);
      setLoading(false);
    } catch (error) {
      console.error(error.message);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setOpenEdit(true);
  };

  const handleDelete = (user) => {
    setSelectedUser(user);
    setOpenDelete(true);
  };

  const columns = [
    {
      field: "full_name",
      headerName: "User Name",
      flex: 0.75,
    },
    { field: "email", headerName: "Email", flex: 1 },
    { field: "role_name", headerName: "Role", flex: 0.55 },
    {
      field: "actions",
      headerName: "Actions",
      width: 100,
      sortable: false,
      filterable: false,
      renderCell: (params) => (
        <>
          <IconButton onClick={() => handleEdit(params.row)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={() => handleDelete(params.row)}>
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="content">
      <Box sx={{ width: "100%" }}>
        <Typography
          variant="h2"
          component="h2"
          gutterBottom
          sx={{
            color: "primary.main",
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Partner Users
        </Typography>
        <Typography
          variant="body1"
          sx={{
            marginBottom: 2,
            textAlign: "left",
          }}
        >
          Partner users are users capable of managing partner-specific resources
          and accessing the partner portal.
        </Typography>
        <Button
          variant="contained"
          color="primary"
          startIcon={<AddIcon />}
          onClick={() => setOpenCreate(true)}
          sx={{ mb: 2 }}
        >
          Add User
        </Button>
        <Box sx={{ mt: 2 }}>
          <DataGridPro
            rows={users}
            columns={columns}
            pageSize={10}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 10,
                },
              },
            }}
            headerFilters
            pageSizeOptions={[10, 25, 50]}
            pagination
            slots={{
              toolbar: CustomToolbar,
              noRowsOverlay: CustomNoRowsOverlay,
              headerFilterMenu: null,
            }}
          />
        </Box>
      </Box>
      <CreateUser
        open={openCreate}
        onClose={() => setOpenCreate(false)}
        refreshUsers={fetchUsers}
      />
      <EditUser
        open={openEdit}
        onClose={() => setOpenEdit(false)}
        user={selectedUser}
        refreshUsers={fetchUsers}
      />
      <DeleteUser
        open={openDelete}
        onClose={() => setOpenDelete(false)}
        user={selectedUser}
        refreshUsers={fetchUsers}
      />
    </div>
  );
}
